import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
// slices
import serviceReducer from './slices/service';
import bankReducer from './slices/bank';
import topupReducer from './slices/topup';
import loyalReducer from './slices/loyal';
import packReducer from './slices/package';
import serverReducer from './slices/server';
import userReducer from './slices/user';
import orderSeedingReducer from './slices/order-seeding';
import postReducer from './slices/post';
import groupPermissionReducer from './slices/permission';
import globalReducer from './slices/global';
import configReducer from './slices/config';
// ----------------------------------------------------------------------

const createNoopStorage = () => ({
  getItem(_key: string) {
    return Promise.resolve(null);
  },
  setItem(_key: string, value: any) {
    return Promise.resolve(value);
  },
  removeItem(_key: string) {
    return Promise.resolve();
  },
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  global: globalReducer,
  config: configReducer,
  service: serviceReducer,
  bank: bankReducer,
  topup: topupReducer,
  loyal: loyalReducer,
  pack: packReducer,
  server: serverReducer,
  user: userReducer,
  orderSeeding: orderSeedingReducer,
  post: postReducer,
  groupPermission: groupPermissionReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
