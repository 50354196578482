import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'redux/store';
import axiosInstance from 'utils/axios';
import { IConfig } from '../../@types/config';
import { IErrorForlikeResponse } from '../../@types/error';
import { AuthUserType, IGlobalState, ISocketMessage } from '../../@types/global';
import { IPost } from '../../@types/post';
import { setDialogRecharge } from './bank';

const initialState: IGlobalState = {
  user: undefined,
  showLogin: false,
  loading: false,
  loadingComponent: false,
  error: false,
  errors: [],
  notifications: [],
  dense: true,
  socketMessage: null,
  configs: [],
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setUserState(state, action) {
      state.user = action.payload;
    },

    setShowLoginState(state, action) {
      state.showLogin = action.payload;
    },

    setNotificationState(state, action) {
      state.notifications = action.payload;
    },

    setConfigsState(state, action) {
      state.configs = action.payload;
    },

    setDenseState(state, action) {
      state.dense = action.payload;
    },

    setLoadingState(state, action) {
      state.loading = action.payload;
    },
    setLoadingComponentState(state, action) {
      state.loadingComponent = action.payload;
    },
    setErrorState(state, action) {
      state.error = true;
      state.errors = action.payload;
    },
    clearErrorState(state) {
      state.error = false;
      state.errors = [];
    },

    setSocketMessageState(state, action) {
      state.socketMessage = action.payload;
    },

    clearSocketMessageState(state) {
      state.socketMessage = null;
    },
  },
});

export default slice.reducer;

// Actions
export const {
  setLoadingState,
  clearErrorState,
  setErrorState,
  setLoadingComponentState,
  setShowLoginState,
  setUserState,
  setSocketMessageState,
  clearSocketMessageState,
} = slice.actions;

export const setDense = (dense: boolean) => {
  dispatch(slice.actions.setDenseState(dense));
};

export const setConfigs = (configs: IConfig[]) => {
  dispatch(slice.actions.setConfigsState(configs));
};

export const setNotifications = (posts: IPost[]) => {
  dispatch(slice.actions.setNotificationState(posts));
};

export const setLoading = (isLoading: boolean, isComponent: boolean = false) => {
  dispatch(isComponent ? setLoadingComponentState(isLoading) : setLoadingState(isLoading));
};

export const setUser = (user?: AuthUserType) => {
  dispatch(setUserState(user));
};

export const setShowLogin = (isShow: boolean = true) => {
  dispatch(setShowLoginState(isShow));
};

export const setError = (errors: IErrorForlikeResponse[] | undefined) => {
  dispatch(setErrorState(errors || [{ message: 'Đã có lỗi xảy ra' }]));
};

export const clearLoading = (isComponent: boolean = false) => {
  setLoading(false, isComponent);
};

export const clearError = () => {
  dispatch(clearErrorState());
};

export const setSocketMessage = (message: ISocketMessage | null) => {
  setDialogRecharge();
  dispatch(setSocketMessageState(message));
};

export const clearSocketMessage = () => {
  dispatch(clearSocketMessageState());
};

export const onLogin = async (email: string, password: string) => {
  try {
    const { data } = await axiosInstance.post<{
      user: AuthUserType;
      accessToken: string;
    }>(
      '/api/v1/user/signin',
      {
        email: email,
        password: password,
      },
      { withCredentials: true }
    );
    const { accessToken, user } = data;
    localStorage.setItem('accessToken', accessToken);
    setUser(user);
    return null;
  } catch (e) {
    return e as { status: number; errors: IErrorForlikeResponse[] };
  }
};

export const onRegister = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  userName: string
) => {
  try {
    const response = await axiosInstance.post<{
      user: AuthUserType;
      accessToken: string;
      refreshToken: string;
    }>('api/v1/user/signup', {
      email: email,
      password: password,
      username: userName,
      firstname: firstName,
      lastname: lastName,
    });
    const { accessToken, user } = response.data;
    localStorage.setItem('accessToken', accessToken);
    setUser(user);
    return null;
  } catch (e) {
    return e as { status: number; errors: IErrorForlikeResponse[] };
  }
};

export const onLogout = async () => {
  try {
    await axiosInstance.post('/api/v1/user/signout');
    localStorage.setItem('accessToken', '');
    setUser();
    return null;
  } catch (e) {
    return e as { status: number; errors: IErrorForlikeResponse[] };
  }
};
