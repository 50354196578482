// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: '/',
    anotherservice: path(ROOTS_DASHBOARD, 'anotherservice'),
    history: path(ROOTS_DASHBOARD, 'history'),
    banking: path(ROOTS_DASHBOARD, 'banking'),
    api: path(ROOTS_DASHBOARD, 'docs'),
    support: path(ROOTS_DASHBOARD, 'support'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, 'user/account'),
    account: path(ROOTS_DASHBOARD, 'user/account'),
  },
  system: {
    root: path(ROOTS_DASHBOARD, 'system'),
    post: path(ROOTS_DASHBOARD, 'system/post'),
    user: path(ROOTS_DASHBOARD, 'system/user'),
    loyal: path(ROOTS_DASHBOARD, 'system/loyal'),
    bank: path(ROOTS_DASHBOARD, 'system/bank'),
    pack: path(ROOTS_DASHBOARD, 'system/pack'),
    server: path(ROOTS_DASHBOARD, 'system/server'),
    service: path(ROOTS_DASHBOARD, 'system/service'),
    topup: path(ROOTS_DASHBOARD, 'system/topup'),
    history: {
      root: path(ROOTS_DASHBOARD, 'system/history'),
      orderseeding: path(ROOTS_DASHBOARD, 'system/history/orderseeding'),
      bank: path(ROOTS_DASHBOARD, 'system/history/bank'),
      transaction: path(ROOTS_DASHBOARD, 'system/history/transaction'),
    },
    report: path(ROOTS_DASHBOARD, 'system/report'),
    config: {
      root: path(ROOTS_DASHBOARD, 'system/config'),
      head: path(ROOTS_DASHBOARD, 'system/config/head'),
      support: path(ROOTS_DASHBOARD, 'system/config/support'),
      all: path(ROOTS_DASHBOARD, 'system/config/all'),
      otherservice: path(ROOTS_DASHBOARD, 'system/config/otherservice'),
    },
  },
};
