import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';
import { IPack, IPackState, IPackCreate } from '../../@types/package';
import { setLoading } from './global';
import { getJSONString } from 'utils/func';
import { ICatchApiForlike } from '../../@types/error';

const initialState: IPackState = {
  currentPack: null,
  isOpenDialogForm: false,
};

const slice = createSlice({
  name: 'pack',
  initialState,
  reducers: {
    setCurrentPackState(state, action) {
      state.currentPack = action.payload;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentPack = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API PACK

export async function createPack(
  datapost: IPackCreate
): Promise<[IPack | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<IPack>('/api/v1/pack', {
      ...datapost,
      template: getJSONString(datapost.template),
      items: getJSONString(datapost.items),
    });
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updatePack(
  datapost: IPackCreate
): Promise<[IPack | null, ICatchApiForlike | null]> {
  try {
    const { currentPack } = store.getState().pack as IPackState;
    setLoading(true);
    const { data } = await axios.put<IPack>(`/api/v1/pack/${currentPack!.id}`, {
      ...datapost,
      template: getJSONString(datapost.template),
      items: getJSONString(datapost.items),
    });
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function getPackById(id: string): Promise<[IPack | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<IPack>(`/api/v1/pack/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deletePack(id: string): Promise<[IPack | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<IPack>(`/api/v1/pack/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentPack(pack: IPack | null) {
  dispatch(actions.setCurrentPackState(pack));
}

//#endregion
