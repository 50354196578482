// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API_SERVER = process.env.NEXT_PUBLIC_HOST_API_SERVER ?? '';
export const HOST_API_SERVICE = process.env.NEXT_PUBLIC_HOST_API_SERVICE ?? '';
export const HOST_API_SOCKET = process.env.NEXT_PUBLIC_HOST_API_SOCKET ?? '';
export const SERVER_SERVICE_URL = process.env.SERVER_SERVICE_URL ?? '';
export const MAPBOX_API = process.env.MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

export const SITE_URL = process.env.SITE_URL ?? 'https://my.forlike.pro';

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
