import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { IGroupPermission, IGroupPermissionState } from '../../@types/permission';

const initialState: IGroupPermissionState = {
  groups: [],
};
const slice = createSlice({
  name: 'grouppermission',
  initialState,
  reducers: {
    getGroupPermissionState(state, action) {
      state.groups = action.payload;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

export async function getGroupPermissions() {
  try {
    const { data } = await axios.get<{ items: IGroupPermission[]; total: number }>(
      `/api/v1/grouppermission`
    );
    dispatch(actions.getGroupPermissionState(data));
  } catch (e) {}
}
