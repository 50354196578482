import { NavListProps, NavSection } from 'components/nav-section/types';
import _ from 'lodash';
import { NextRouter } from 'next/router';
import { JSXElementConstructor, ReactElement } from 'react';
import { ITypeGroup } from '../@types/service';
import { fDateParam } from './formatTime';

export const TransferMenuToNav = (
  menus: ITypeGroup[],
  getIcon: (type: String) => ReactElement<any, string | JSXElementConstructor<any>> | undefined
) => {
  return menus.map((type) => {
    const items = _(type.items)
      .orderBy(['sort'], ['desc'])
      .map((group) => {
        return {
          title: group.group,
          icon: getIcon(group.group.toLocaleLowerCase()),
          path: `/${type.type}/${group.group.toLocaleLowerCase()}/`,
          children: _(group.items)
            .orderBy(['sort'], ['asc'])
            .map(
              (item) =>
                ({
                  title: item.displayname,
                  path: `/${type.type}/${group.name.toLocaleLowerCase()}/${item.url}/order`,
                } as NavListProps)
            )
            .value(),
        } as NavListProps;
      })
      .value();

    return {
      subheader: type.type || 'Other',
      items,
    } as NavSection;
  });
};

export const getJSONString = (json: JSON) => {
  if (Array.isArray(json)) return JSON.stringify(json);
  if (typeof json === 'object') {
    if (Object.keys(json).length === 0) return '';
  }

  return JSON.stringify(json);
};

export const routerPage = (
  router: NextRouter,
  page: string | number = 0,
  limit: string | number = 10,
  search = '',
  fDate?: Date | null,
  tDate?: Date | null
) => {
  const query: any = {
    page: page,
    limit: limit,
    search: search,
  };
  if (fDate) query.fromDate = fDateParam(fDate);
  if (tDate) query.toDate = fDateParam(tDate);

  router.push(
    {
      pathname: location.pathname,
      query: query,
    },
    undefined,
    {
      scroll: false,
    }
  );
};

export const getFullLink = (uid: string, serviceName: string, fullLink?: string): string => {
  if (uid?.includes('http')) return uid;
  else {
    switch (true) {
      case serviceName.includes('facebook'):
        return `https://facebook.com/${uid}`;
      case serviceName.includes('youtube'):
        return `https://youtube.com/watch?v=${uid}`;
      case serviceName.includes('tiktok'):
        return `https://tiktok.com/@${uid}`;
      default:
        return fullLink ?? uid;
    }
  }
};

export const getCachePostId = () => {
  const data = sessionStorage.getItem('notification');
  if (data) return JSON.parse(data) as string[];
  else return [];
};

export const setCachePostId = (postIds: string[]) => {
  sessionStorage.setItem('notification', JSON.stringify(postIds));
};

export const getSessionCache = (name: string) => {
  const data = sessionStorage.getItem(name);
  if (data) return JSON.parse(data);
  else return null;
};

export const setSessionCache = (name: string, value: Object) => {
  sessionStorage.setItem(name, JSON.stringify(value));
};

export const convertStringToArray = (data: string, char: string = '\n') =>
  data.split(char).filter((line) => line.trim() !== '');

export const getDateParamDefault = () => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  const lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 7);

  return [lastMonth, date] as [Date, Date];
};

export const isHTML = (str: string) => {
  const htmlRegex = /<[a-z][\s\S]*>/;

  return htmlRegex.test(str);
};
