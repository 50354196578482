import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch, store } from '../store';
import { ILoyalState, ILoyal, ILoyalCreate } from '../../@types/loyal';
import { setLoading } from './global';
import { ICatchApiForlike } from '../../@types/error';

const initialState: ILoyalState = {
  fullLoyals: [],
  currentLoyal: null,
  isOpenDialogForm: false,
};

const slice = createSlice({
  name: 'loyal',
  initialState,
  reducers: {
    setCurrentLoyalState(state, action) {
      state.currentLoyal = action.payload;
    },

    setFullLoyals(state, action) {
      state.fullLoyals = action.payload.items;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentLoyal = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API

export async function getFullLoyals(): Promise<[ILoyal[] | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<{ items: ILoyal[]; total: number }>(`/api/v1/loyal`);
    dispatch(actions.setFullLoyals(data));
    setLoading(false);
    return [data.items, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function createLoyal(
  datapost: ILoyalCreate
): Promise<[ILoyal | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<ILoyal>('/api/v1/loyal', datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updateLoyal(
  datapost: ILoyalCreate
): Promise<[ILoyal | null, ICatchApiForlike | null]> {
  try {
    const { currentLoyal } = store.getState().loyal as ILoyalState;
    if ('name' in datapost) {
      //@ts-ignore
      datapost.name = undefined;
    }
    setLoading(true);
    const { data } = await axios.put<ILoyal>(`/api/v1/loyal/${currentLoyal!.id}`, datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deleteLoyal(id: string): Promise<[ILoyal | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<ILoyal>(`/api/v1/loyal/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentLoyal(loyal: ILoyal | null) {
  dispatch(actions.setCurrentLoyalState(loyal));
}

//#endregion
