import { createSlice } from '@reduxjs/toolkit';
import { IPostState, IPost, IPostCreate } from '../../@types/post';
import { setLoading } from './global';
import { dispatch, store } from 'redux/store';
import { ICatchApiForlike } from '../../@types/error';
import axios from 'utils/axios';

const initialState: IPostState = {
  fullPosts: [],
  isOpenDialogForm: false,
  currentPost: null,
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setFullPosts(state, action) {
      state.fullPosts = action.payload.items;
    },

    setCurrentPostState(state, action) {
      state.currentPost = action.payload;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentPost = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API POST

export async function getFullPosts() {
  try {
    setLoading(true);
    const { data } = await axios.get<{ items: IPost[]; total: number }>(`/api/v1/post`);
    dispatch(actions.setFullPosts(data));
  } catch (e) {
    setLoading(false);
    return e as ICatchApiForlike;
  } finally {
    setLoading(false);
    return null;
  }
}

export async function createPost(
  datapost: IPostCreate
): Promise<[IPost | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);

    let formData = new FormData();
    formData.append('content', datapost.content);
    formData.append('isNotification', datapost.isNotification.toString());
    formData.append('isShow', datapost.isShow.toString());
    datapost.images.map((file, index) => formData.append('image' + index, file));
    const { data } = await axios.post<IPost>('/api/v1/post', formData);
    setOpenDialogForm();
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updatePost(
  datapost: IPostCreate
): Promise<[IPost | null, ICatchApiForlike | null]> {
  try {
    const { currentPost } = store.getState().post as IPostState;
    setLoading(true);
    let formData = new FormData();
    formData.append('content', datapost.content);
    formData.append('isNotification', datapost.isNotification.toString());
    formData.append('isShow', datapost.isShow.toString());
    datapost.images.map((file, index) => formData.append('image' + index, file));

    const { data } = await axios.put<IPost>(`/api/v1/post/${currentPost?.id}`, formData);
    setOpenDialogForm();
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deletePost(id: string): Promise<[IPost | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<IPost>(`/api/v1/post/${id}`);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentPost(pack: IPost | null) {
  dispatch(actions.setCurrentPostState(pack));
}

//#endregion
