import { createSlice } from '@reduxjs/toolkit';
import { IBank, IBankCreate, IBankNapTheCao, IBankState, IBankTheCao } from '../../@types/bank';
import { ICatchApiForlike } from '../../@types/error';
import axios from '../../utils/axios';
import { dispatch, store } from '../store';
import { setLoading } from './global';

const initialState: IBankState = {
  fullBanks: [],
  currentBank: null,
  isOpenDialogForm: false,
  isRecharge: false,
};

const slice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    setCurrentBankState(state, action) {
      state.currentBank = action.payload;
    },

    setFullBanks(state, action) {
      state.fullBanks = action.payload.items;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentBank = null;
    },

    setIsRecharge(state, action) {
      state.isRecharge = action.payload;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API SERVER

export async function getFullBanks(): Promise<[IBank[] | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<{ items: IBank[]; total: number }>(`/api/v1/bank`);
    dispatch(actions.setFullBanks(data));
    setLoading(false);
    return [data.items, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function createBank(
  datapost: IBankCreate
): Promise<[IBank | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<IBank>('/api/v1/bank', datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updateBank(
  datapost: IBankCreate
): Promise<[IBank | null, ICatchApiForlike | null]> {
  try {
    const { currentBank } = store.getState().bank as IBankState;
    // if ('bankname' in datapost) {
    //   //@ts-ignore
    //   datapost.bankname = undefined; // Hoặc có thể gán null
    // }
    setLoading(true);
    const { data } = await axios.put<IBank>(`/api/v1/bank/${currentBank!.id}`, datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deleteBank(id: string): Promise<[IBank | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<IBank>(`/api/v1/bank/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentBank(bank: IBank | null) {
  dispatch(actions.setCurrentBankState(bank));
}

export async function getTheoCaos(
  bankId: string
): Promise<[IBankTheCao[], ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<IBankTheCao[]>(`/api/v1/bank/${bankId}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [[], e as ICatchApiForlike];
  }
}

export async function napTheCao(
  bankId: string,
  datapost: IBankNapTheCao
): Promise<[IBankNapTheCao | null, ICatchApiForlike | null]> {
  try {
    setDialogRecharge(true);
    const { data } = await axios.put<IBankNapTheCao>(`/api/v1/bank/napthe/${bankId}`, datapost);
    return [data, null];
  } catch (e) {
    setDialogRecharge();
    return [null, e as ICatchApiForlike];
  }
}

export async function setDialogRecharge(isShow: boolean = false) {
  dispatch(actions.setIsRecharge(isShow));
}

//#endregion
