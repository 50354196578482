import axios from 'axios';
// config
import { HOST_API_SERVER, HOST_API_SERVICE } from '../config';

// ----------------------------------------------------------------------
const isService = typeof window === 'undefined';

const axiosInstance = axios.create({
  baseURL: isService ? HOST_API_SERVER : HOST_API_SERVICE,
  headers: {
    Authorization: !isService ? `Bearer ${localStorage.getItem('accessToken')}` : undefined,
  },
});

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !isService) {
      axios.defaults.withCredentials = true;

      try {
        const { data } = await axios.post<{ accessToken: string }>(
          `${isService ? HOST_API_SERVER : HOST_API_SERVICE}/api/v1/user/refreshtoken`
        );
        localStorage.setItem('accessToken', data.accessToken);

        const config = error.config;
        config.headers.Authorization = `Bearer ${data.accessToken}`;
        location.reload();
        return await axios.request(config);
      } catch (e) {
        return Promise.reject(
          {
            status: e.response && e.response.status,
            errors: e.response && e.response.data.errors,
          } || { status: 500, errors: [{ message: 'Đã có lỗi xảy ra' }] }
        );
      }
    } else {
      return Promise.reject(
        {
          status: error.response && error.response.status,
          errors: error.response && error.response.data.errors,
        } || { status: 500, errors: [{ message: 'Đã có lỗi xảy ra' }] }
      );
    }
  }
);

export default axiosInstance;
