export const ConfigKey = {
  SiteName: 'sitename',
  Head: 'head',
  Meta: 'meta',
  Support: 'support',
  OtherService: 'otherservice',
  GTag: 'gtag',
  Api: 'api',
  CongTacVien: 'congtacvien',
  Terms: 'terms',
  TextPay: 'textpay',
};

export const TypeService = {
  OrderSeeding: 'orderseeding',
  Via: 'via',
};
