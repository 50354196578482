import { createSlice } from '@reduxjs/toolkit';
import { getJSONString } from 'utils/func';
import { ICatchApiForlike } from '../../@types/error';
import { IServer } from '../../@types/server';
import {
  IService,
  IServiceCreate,
  IServiceState,
  ITypeGroup,
  IViaDanhMuc,
} from '../../@types/service';
import { default as axios, default as axiosInstance } from '../../utils/axios';
import { dispatch, store } from '../store';
import { setLoading } from './global';

const initialState: IServiceState = {
  menus: [],
  servers: [],
  danhMucs: [],
  fullServices: [],
  currentService: null,
  isOpenDialogForm: false,
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setMenus(state, action) {
      state.menus = action.payload;
    },

    setCurrentServiceState(state, action) {
      state.currentService = action.payload;
    },

    setFullServices(state, action) {
      state.fullServices = action.payload.items;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentService = null;
    },

    setDanhMucState(state, action) {
      (state.servers = action.payload.servers), (state.danhMucs = action.payload.danhMucs);
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION LOADMENU
export async function setMenus(menus: ITypeGroup[]) {
  dispatch(actions.setMenus(menus));
}
//#endregion

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API SERVER

export async function getFullServices(): Promise<[IService[] | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<{ items: IService[]; total: number }>(`/api/v1/service`);
    dispatch(actions.setFullServices(data));
    setLoading(false);
    return [data.items, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function getServiceById(
  id: string
): Promise<[IService | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<IService>(`/api/v1/service/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function createService(
  datapost: IServiceCreate
): Promise<[IService | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<IService>('/api/v1/service', {
      ...datapost,
      template: getJSONString(datapost.template),
      serviceprovider: getJSONString(datapost.serviceprovider),
    });
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updateService(
  datapost: IServiceCreate
): Promise<[IService | null, ICatchApiForlike | null]> {
  try {
    const { currentService } = store.getState().service as IServiceState;
    setLoading(true);
    const { data } = await axios.put<IService>(`/api/v1/service/${currentService!.id}`, {
      ...datapost,
      template: getJSONString(datapost.template),
      serviceprovider: getJSONString(datapost.serviceprovider),
    });
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deleteService(
  id: string
): Promise<[IService | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<IService>(`/api/v1/service/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentService(service: IService | null) {
  dispatch(actions.setCurrentServiceState(service));
}

export async function loadDanhMucData() {
  try {
    const [server, danhmuc] = await Promise.all([
      axiosInstance.get<{ items: IServer[] }>(`/api/v1/server`),
      axiosInstance.get<IViaDanhMuc[]>(`/api/v1/service/catalogue`),
    ]);

    dispatch(actions.setDanhMucState({ servers: server.data.items, danhMucs: danhmuc.data }));
  } catch (err) {
    //
  }
}

//#endregion
