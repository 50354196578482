import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'redux/store';
import { IConfig, IConfigState, ICreateConfig } from '../../@types/config';
import { ICatchApiForlike } from '../../@types/error';
import axios from '../../utils/axios';
import { setLoading } from './global';

const initialState: IConfigState = {
  isOpenDialogForm: false,
  currentConfig: null,
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setCurrentConfigState(state, action) {
      state.currentConfig = action.payload;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentConfig = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API CONFIG

export async function getConfig(): Promise<[IConfig[] | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<IConfig[]>(`/api/v1/config/${location.hostname}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function getConfigByName(
  name: string
): Promise<[IConfig | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<IConfig>(`/api/v1/config/${location.hostname}/${name}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function createConfig(
  datapost: ICreateConfig
): Promise<[IConfig | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    datapost.domain = location.host;
    const { data } = await axios.post<IConfig>('/api/v1/config', datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updateConfig(
  id: string,
  datapost: ICreateConfig
): Promise<[IConfig | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    datapost.domain = location.host;
    const { data } = await axios.put<IConfig>(`/api/v1/config/${id}`, datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deleteConfig(id: string): Promise<[IConfig | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<IConfig>(`/api/v1/config/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentConfig(data: IConfig | null) {
  dispatch(actions.setCurrentConfigState(data));
}

//#endregion
