import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from 'redux/store';
import { getJSONString } from 'utils/func';
import { ICatchApiForlike } from '../../@types/error';
import { IServer, IServerCreate, IServerPack, IServerState } from '../../@types/server';
import axios from '../../utils/axios';
import { setLoading } from './global';

const initialState: IServerState = {
  fullServers: [],
  isOpenDialogForm: false,
  currentServer: null,
};

const slice = createSlice({
  name: 'server',
  initialState,
  reducers: {
    setFullServers(state, action) {
      state.fullServers = action.payload.items;
    },

    setCurrentServerState(state, action) {
      state.currentServer = action.payload;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentServer = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API SERVER

export async function getFullServers(): Promise<[IServer[] | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<{ items: IServer[]; total: number }>(`/api/v1/server`);
    dispatch(actions.setFullServers(data));
    setLoading(false);
    return [data.items, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function getPacksWithServer(serverId: string): Promise<IServerPack[]> {
  try {
    const { data } = await axios.get<IServerPack[]>(`/api/v1/server/pack/${serverId}`);
    return data;
  } catch (e) {
    return [];
  }
}

export async function createServer(
  datapost: IServerCreate
): Promise<[IServer | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<IServer>('/api/v1/server', {
      ...datapost,
      template: getJSONString(datapost.template),
    });
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updateServer(
  datapost: IServerCreate
): Promise<[IServer | null, ICatchApiForlike | null]> {
  try {
    const { currentServer } = store.getState().server as IServerState;
    setLoading(true);
    const { data } = await axios.put<IServer>(`/api/v1/server/${currentServer!.id}`, {
      ...datapost,
      template: getJSONString(datapost.template),
    });
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deleteServer(id: string): Promise<[IServer | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<IServer>(`/api/v1/server/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentServer(pack: IServer | null) {
  dispatch(actions.setCurrentServerState(pack));
}

//#endregion
