import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch, store } from '../store';
import { ITopup, ITopupState, ITopupCreate } from '../../@types/topup';
import { setError, setLoading } from './global';
import { ICatchApiForlike } from '../../@types/error';

const initialState: ITopupState = {
  fullTopups: [],
  currentTopup: null,
  isOpenDialogForm: false,
};

const slice = createSlice({
  name: 'topup',
  initialState,
  reducers: {
    setCurrentTopupState(state, action) {
      state.currentTopup = action.payload;
    },

    setFullTopups(state, action) {
      state.fullTopups = action.payload.items;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentTopup = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API

export async function getFullTopups(): Promise<[ITopup[] | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.get<{ items: ITopup[]; total: number }>(`/api/v1/topup`);
    dispatch(actions.setFullTopups(data));
    setLoading(false);
    return [data.items, null];
  } catch (e) {
    setLoading(false);
    return [[], e as ICatchApiForlike];
  }
}

export async function createTopup(
  datapost: ITopupCreate
): Promise<[ITopup | null, ICatchApiForlike | null]> {
  try {
    if (datapost.istopup) {
      datapost.startdate = undefined;
      datapost.enddate = undefined;
    }
    setLoading(true);
    const { data } = await axios.post<ITopup>('/api/v1/topup', datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updateTopup(
  datapost: ITopupCreate
): Promise<[ITopup | null, ICatchApiForlike | null]> {
  try {
    const { currentTopup } = store.getState().topup as ITopupState;
    if (datapost.istopup) {
      datapost.startdate = undefined;
      datapost.enddate = undefined;
    }
    setLoading(true);
    const { data } = await axios.put<ITopup>(`/api/v1/topup/${currentTopup!.id}`, datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deleteTopup(id: string): Promise<[ITopup | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<ITopup>(`/api/v1/topup/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentTopup(topup: ITopup | null) {
  dispatch(actions.setCurrentTopupState(topup));
}

//#endregion
