import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { IOrderSeedingState, IOrderSeeding, IOrderSeedingCreate } from '../../@types/order';
import { setLoading } from './global';
import { dispatch, store } from 'redux/store';
import { ICatchApiForlike } from '../../@types/error';

const initialState: IOrderSeedingState = {
  isOpenDialogForm: false,
  currentOrder: null,
};

const slice = createSlice({
  name: 'orderSeeding',
  initialState,
  reducers: {
    setCurrentOrderState(state, action) {
      state.currentOrder = action.payload;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentOrder = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API ORDER
export async function getOrderSeeding(
  page?: string,
  limit?: string,
  search?: string,
  fromDate?: string,
  toDate?: string,
  serviceId?: string
) {
  try {
    setLoading(true);
    const { data } = await axios.get<{ items: IOrderSeeding[]; total: number }>(
      `/api/v1/order/orderseeding?limit=${limit ?? 10}&page=${page ?? 0}&search=${
        search ?? ''
      }&service=${serviceId}${fromDate && `&fromDate=${fromDate.toString()}`}${
        toDate && `&toDate=${toDate.toString()}`
      }`
    );
    setLoading(false);
    return data;
  } catch (e) {
    setLoading(false);
    return null;
  }
}

export async function createOrder(
  datapost: IOrderSeedingCreate
): Promise<[IOrderSeeding | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<IOrderSeeding>('/api/v1/order/orderseeding', datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function resumeOrder(
  orderId: string
): Promise<[IOrderSeeding | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.put<IOrderSeeding>(`/api/v1/order/orderseeding/resume/${orderId}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function doneOrder(
  orderId: string
): Promise<[IOrderSeeding | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.put<IOrderSeeding>(`/api/v1/order/orderseeding/done/${orderId}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function refundOrder(
  orderId: string
): Promise<[IOrderSeeding | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.put<IOrderSeeding>(`/api/v1/order/orderseeding/refund/${orderId}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function warrantyOrder(
  orderId: string
): Promise<[IOrderSeeding | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.put<IOrderSeeding>(
      `/api/v1/order/orderseeding/warranty/${orderId}`
    );
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function supportOrder(
  orderId: string
): Promise<[IOrderSeeding | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.put<IOrderSeeding>(
      `/api/v1/order/orderseeding/support/${orderId}`
    );
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentOrder(order: IOrderSeeding | null) {
  dispatch(actions.setCurrentOrderState(order));
}

//#endregion
