import { format, getTime, formatDistanceToNow, formatDistance } from 'date-fns';
import viLocale from 'date-fns/locale/vi';
// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export const fDateTimeUpdate = (date: Date | string) =>
  format(new Date(date), "yyyy-MM-dd'T'HH:mm");

export const fDateTime = (date: Date | string) => format(new Date(date), 'HH:mm dd/MM/yyyy');

export const fDateParam = (date: Date | string) => format(new Date(date), 'yyyy-MM-dd');

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function fToExpired(expdate: Date | string | number) {
  if (new Date() < new Date(expdate))
    return formatDistance(new Date(expdate), new Date(), {
      addSuffix: true,
      locale: viLocale,
    });
  else return 'Hết hạn';
}
