// i18n
import '../locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-markdown-editor-lite/lib/index.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { CacheProvider, EmotionCache } from '@emotion/react';
// next
import { NextComponentType, NextPage, NextPageContext } from 'next';
import { AppContext, AppProps } from 'next/app';
// redux
import { Provider as ReduxProvider } from 'react-redux';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// redux
import { store } from '../redux/store';
// utils
import createEmotionCache from '../utils/createEmotionCache';
// theme
import ThemeProvider from '../theme';
import ThemeLocalization from '../locales';
import { StyledChart } from '../components/chart';
import ProgressBar from '../components/progress-bar';
import SnackbarProvider from '../components/snackbar';
import { MotionLazyContainer } from '../components/animate';
import { ThemeSettings, SettingsProvider } from '../components/settings';
import axios, { AxiosInstance } from 'axios';
import axiosInstance from 'utils/axios';
import { IBank } from '../@types/bank';
import { ITopup } from '../@types/topup';
import React, { useEffect } from 'react';
import { setConfigs, setNotifications, setShowLogin, setUser } from 'redux/slices/global';
import { checkMe } from 'redux/slices/user';
import { ITypeGroup } from '../@types/service';
import { setMenus } from 'redux/slices/service';
import { IPost } from '../@types/post';
import { IConfig } from '../@types/config';
import Head from 'next/head';
import uuidv4 from 'utils/uuidv4';
import { SERVER_SERVICE_URL } from 'config';
import { ConfigKey } from 'utils/constant';
import ErrorBoundary from 'components/ErrorBoundary';
// ----------------------------------------------------------------------

const clientSideEmotionCache = createEmotionCache();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
  menus: ITypeGroup[];
  notifications: IPost[];
  dataConfigs: IConfig[];
  SiteName?: string;
}

export const renderHeader = (datas: IConfig[]) => {
  if (!Array.isArray(datas)) {
    return null;
  }

  return datas
    .filter((f) => f.name === ConfigKey.Head)
    .map((tag) => {
      if (tag.type === 'title') return <title key={tag.value}>{tag.value}</title>;
      if (tag.type === 'gtag') return null;

      try {
        const parsedData = JSON.parse(tag.value);

        if (typeof parsedData === 'object' && parsedData !== null && tag.type) {
          return React.createElement(tag.type, { key: uuidv4(), ...parsedData });
        }
      } catch (error) {
        // Xử lý lỗi parse JSON (nếu cần)
      }
      return null;
    });
};

function MyApp(props: MyAppProps) {
  let {
    Component,
    pageProps,
    emotionCache = clientSideEmotionCache,
    router,
    __N_SSG,
    __N_SSP,
    menus,
    ...other
  } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  const { notifications, dataConfigs } = other;

  useEffect(() => {
    const loadData = async () => {
      const user = await checkMe();
      if (user) {
        setUser(user);
      } else {
        setUser();
        setShowLogin();
      }
    };
    loadData();
    setMenus(menus);
    setNotifications(notifications);
    setConfigs(dataConfigs);
  }, []);

  const gtag = dataConfigs.find((f) => f.type === ConfigKey.GTag);
  const SiteName = dataConfigs.find((f) => f.type === ConfigKey.SiteName);

  other = { ...other, SiteName: SiteName?.value ?? '' };

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        {renderHeader(dataConfigs)}
        {gtag?.value && (
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtag.value}`}></script>
        )}
        {gtag?.value && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag.value}');
              `,
            }}
          ></script>
        )}
      </Head>
      <ReduxProvider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <MotionLazyContainer>
              <ThemeProvider>
                <ThemeSettings>
                  <ThemeLocalization>
                    <SnackbarProvider>
                      <StyledChart />
                      <ProgressBar />
                      {/* @ts-ignore */}
                      {getLayout(
                        <ErrorBoundary>
                          <Component {...pageProps} {...other} />
                        </ErrorBoundary>
                      )}
                    </SnackbarProvider>
                  </ThemeLocalization>
                </ThemeSettings>
              </ThemeProvider>
            </MotionLazyContainer>
          </SettingsProvider>
        </LocalizationProvider>
      </ReduxProvider>
    </CacheProvider>
  );
}

type AppContextRoot = AppContext & {
  Component: NextComponentType<NextPageContext, {}, {}> & {
    getInitialProps: (context: NextPageContext, client: AxiosInstance) => {} | Promise<{}>;
  };
};

MyApp.getInitialProps = async (appContext: AppContextRoot) => {
  appContext.ctx.res?.setHeader(
    'Cache-Control',
    'public, s-maxage=3600, stale-while-revalidate=59'
  );
  let pageProps: any = {};

  let banks: IBank[] = [];
  let topups: ITopup[] = [];
  let menus: ITypeGroup[] = [];
  let notifications: IPost[] = [];
  let dataConfigs: IConfig[] = [];
  try {
    let serviceAxios;
    if (SERVER_SERVICE_URL) {
      serviceAxios = axios.create({ baseURL: SERVER_SERVICE_URL });
    } else serviceAxios = axiosInstance;

    const { data } = await serviceAxios.get<{
      banks: IBank[];
      topups: ITopup[];
      menus: ITypeGroup[];
      notifications: IPost[];
      configs: IConfig[];
    }>(`/api/v1/config/public/${appContext.ctx.req?.headers.host}`);

    banks = data.banks;
    topups = data.topups;
    menus = data.menus;
    notifications = data.notifications;
    dataConfigs = data.configs;

    if (appContext.Component.getInitialProps) {
      pageProps = await appContext.Component.getInitialProps(appContext.ctx, axiosInstance);
    }
  } catch (e) {
    console.log('APP.TS lol', e);
  }
  return {
    pageProps,
    topups,
    banks,
    menus,
    notifications,
    dataConfigs,
  };
};

export default MyApp;
