import { createSlice } from '@reduxjs/toolkit';
import { ICatchApiForlike } from '../../@types/error';
import { AuthUserType } from '../../@types/global';
import {
  IAccountChangePassword,
  IAccountLinks,
  IAccountNotification,
  IAccountUpdate,
  ICoinAction,
  IUser,
  IUserState,
  IUserUpdate,
} from '../../@types/user';
import axios from '../../utils/axios';
import { dispatch, store } from '../store';
import { setLoading, setUser } from './global';

const initialState: IUserState = {
  currentUser: null,
  isOpenDialogForm: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUserState(state, action) {
      state.currentUser = action.payload;
    },

    setOpenDialogFormState(state, action) {
      state.isOpenDialogForm = action.payload;
      if (!action.payload) state.currentUser = null;
    },
  },
});

export default slice.reducer;

export const { actions } = slice;

//#region FUNCTION FORM
export const setOpenDialogForm = (isOpen: boolean = false) => {
  dispatch(actions.setOpenDialogFormState(isOpen));
};
//#endregion

//#region API

export async function checkMe() {
  try {
    const { data } = await axios.get<{ user: AuthUserType }>('/api/v1/user/me');
    setUser(data.user);
    return data.user;
  } catch (e) {
    return undefined;
  }
}

export async function createUser(
  datapost: IUserUpdate
): Promise<[IUser | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<IUser>('/api/v1/user', datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function updateUser(
  datapost: IUserUpdate
): Promise<[IUser | null, ICatchApiForlike | null]> {
  try {
    const { currentUser } = store.getState().user as IUserState;
    setLoading(true);
    const { data } = await axios.put<IUser>(`/api/v1/user/${currentUser!.id}`, datapost);
    setOpenDialogForm();
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function deleteUser(id: string): Promise<[IUser | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.delete<IUser>(`/api/v1/user/${id}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export function setCurrentUser(user: IUser | null) {
  dispatch(actions.setCurrentUserState(user));
}

//#endregion

//#region ACTION COIN
export async function coinRecharge(
  userId: string,
  coinAction: ICoinAction
): Promise<[IUser | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<IUser>(`/api/v1/user/${coinAction.action}/${userId}`, {
      coin: coinAction.coin,
      content: coinAction.content,
      code: coinAction.code,
    });
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function resetPassword(
  userId: string,
  password: string
): Promise<[{ user: IUser; password: string } | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.post<{ user: IUser; password: string }>(
      `/api/v1/user/resetpassword/${userId}`,
      {
        password,
      }
    );
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

export async function setStatusUser(
  userId: string
): Promise<[IUser | null, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    const { data } = await axios.put<IUser>(`/api/v1/user/changestatus/${userId}`);
    setLoading(false);
    return [data, null];
  } catch (e) {
    setLoading(false);
    return [null, e as ICatchApiForlike];
  }
}

//#endregion

//#region Account Action
export async function changePassword(
  data: IAccountChangePassword
): Promise<[boolean, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    await axios.post<boolean>(`/api/v1/user/changepassword`, data);
    setLoading(false);
    return [true, null];
  } catch (e) {
    setLoading(false);
    return [false, e as ICatchApiForlike];
  }
}

export async function getTokenApi(): Promise<[boolean, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    await axios.post<string>(`/api/v1/user/token`);
    setLoading(false);
    return [true, null];
  } catch (e) {
    setLoading(false);
    return [false, e as ICatchApiForlike];
  }
}

export async function accountUpdateProfile(
  data: IAccountUpdate
): Promise<[boolean, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    await axios.put<boolean>(`/api/v1/user/profile`, data);
    setLoading(false);
    return [true, null];
  } catch (e) {
    setLoading(false);
    return [false, e as ICatchApiForlike];
  }
}

export async function accountUpdateNotification(
  data: IAccountNotification
): Promise<[boolean, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    await axios.put<boolean>(`/api/v1/user/notification`, data);
    setLoading(false);
    return [true, null];
  } catch (e) {
    setLoading(false);
    return [false, e as ICatchApiForlike];
  }
}

export async function accountUpdateLink(
  data: IAccountLinks
): Promise<[boolean, ICatchApiForlike | null]> {
  try {
    setLoading(true);
    await axios.put<boolean>(`/api/v1/user/link`, data);
    setLoading(false);
    return [true, null];
  } catch (e) {
    setLoading(false);
    return [false, e as ICatchApiForlike];
  }
}
//#endregion
